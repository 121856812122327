.marqueeContainer {
  width: 60%;
  cursor: pointer;
}

.marqueeSlide {
  margin-right: 4rem;
}

.sponsorImage {
  transform: scale(0.43);
}

@media (max-width: 660px) {
  .marqueeContainer {
    width: 100%;
    cursor: pointer;
  }
}
